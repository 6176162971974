/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MessageList } from "react-chat-elements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMicrophone, faCirclePause, faCirclePlay, faMicrophoneSlash, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Lottie from "lottie-react";
import speakerAnimation from '../../assets/speaker.json'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CgDanger } from "react-icons/cg";
import { IoMdCall } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaLink } from "react-icons/fa6";
import { BiChat, BiGlobe } from 'react-icons/bi';
import { LiaSignLanguageSolid } from "react-icons/lia";

import { startTextConversation, textConversation, documentConversation, _setLlmAPI, _getLlmAPI, _scrapWebsite } from '../../api/Api';
import logo from '../../assets/images/logo.png'
import { agentLogo, agentName } from '../../constant';
import FormModal from './FormModal';
import convertMarkdownToHtml from '../../utils/utils';
import LanguageInput from './LanguageInput';
import Interpreter from './Interpreter';
import RemoteInterpreter from '../RemoteInterpreter/RemoteInterpreter';
import Instruction from './Instruction';

function Home({ setCall }) {
    const [sessionId, setSessionId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [aiText, setAiText] = useState('');
    const messageListRef = useRef(null);
    const animationRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [disablePlay, setDisablePlay] = useState(true);
    const [listeningActive, setListeningActive] = useState(false);
    const playRef = useRef(false);
    const voicesRef = useRef([]);
    const langsRef = useRef([]);
    const [filteredVoice, setFilteredVoice] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [selectedLang, setSelectedLang] = useState(null);
    const {
        transcript,
        listening
    } = useSpeechRecognition();

    // Agent and LLM Selection
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Aria-W3villa-AI-Agent');
    const dropdownRef = useRef(null);
    const options = ['Aria-W3villa-AI-Agent', 'school-teacher', 'e-commerce', 'pizzaHome', 'Translator', 'Servotech'];
    const [selectedAgent, setSelectedAgent] = useState('Aria-W3villa-AI-Agent');

    const [isLlmsOpen, setIsLlmsOpen] = useState(false);
    const [selectedLlm, setSelectedLlm] = useState('openai');
    const llmsDropdownRef = useRef(null);
    const llms = { 'openai': 'OpenAi', 'groq': 'Groq' };
    const [selectedLlmOption, setSelectedLlmOption] = useState("openai");

    // Modals and File Upload
    const [selectedFile, setSelectedFile] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [webUrl, setWebUrl] = useState('');
    const [showInterpreter, setShowInterpreter] = useState(false);
    const [showRemoteInterpreter, setShowRemoteInterpreter] = useState(false);
    const [animationSpeed, setAnimationSpeed] = useState(0);

    const [showModals, setShowModals] = useState({
        documentModal: false,
        webUrlModal: false,
        callModal: false,
        llmsModal: false,
        agentModal: false
    });
    const [languageSelection, setLanguageSelection] = useState({
        from: "English",
        to: "English",
    });

    // Event Handlers
    const handleDocumentClick = useCallback(() => {
        playRef.current = true;
        document.removeEventListener("click", handleDocumentClick);
    }, []);

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handlePlayPause = () => {
        if (disablePlay) {
            return;
        }

        if (isPlaying) {
            window.speechSynthesis.cancel();
            setIsPlaying(false);
            setAnimationSpeed(0);

            if (listeningActive) {
                setTimeout(() => {
                    SpeechRecognition.startListening();
                }, 1000);
            }
        }
    };

    const handleVoiceChange = (input) => {
        const selectedVoiceName = typeof input === "string" ? input : input.target.value;
        const selectedVoice = filteredVoice.find(voice => voice.name === selectedVoiceName);
        setSelectedVoice(selectedVoice);
        setIsActive(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleLlmsDropdown = () => {
        setIsLlmsOpen(!isLlmsOpen);
    };

    const selectOption = (option) => {
        handleModalToggle('agentModal', true);
        setSelectedAgent(option);
    };

    const selectLlm = (option) => {
        handleModalToggle('llmsModal', true);
        setSelectedLlm(option);
    };

    const handleAgentToggel = () => {
        setSelectedOption(selectedAgent);
        setIsOpen(false);
        setSessionId(null);
        setMessages([]);
        handleModalToggle('agentModal', false);
        setIsActive(false);
    };

    const handleLlmToggel = async () => {
        setIsLlmsOpen(false);
        handleModalToggle('llmsModal', false);
        setIsActive(false);

        const response = await setLlmAPI();

        if (response?.status === 200) {
            setSelectedLlmOption(selectedLlm);
            toast.success("LLM API updated Successfully", {
                position: "top-center",
                theme: "colored",
            });
        }
    };

    const handleModalToggle = (modalName, show) => {
        setShowModals(prevState => ({
            ...prevState,
            [modalName]: show,
        }));
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUrlChange = (event) => {
        setWebUrl(event.target.value);
    };

    const toggleClass = () => {
        setIsActive(!isActive);
    };

    // API Calls
    const handleSendMessage = useCallback(async (userText = inputValue) => {
        const newMessage = messageFormat("right", "text", "You", userText);
        appendMessage(newMessage);
        setInputValue('');
        addTypingIndicator();

        const startTime = performance.now();
        const agent = agentName[selectedOption + "Web"];

        try {
            let response;
            if (!sessionId) {
                response = await startTextConversation({ body: { "user_text": userText, "agent": agent, language_translate: languageSelection } });
                setSessionId(response.data.sessionId);
            } else {
                response = await textConversation({ body: { "user_text": userText }, sessionId: sessionId });
            }
            handleApiResponse(response, 'text');

            const endTime = performance.now();
            const elapsedTimeSec = (endTime - startTime) / 1000;
            console.log(`API call took ${elapsedTimeSec.toFixed(2)} seconds`);
        } catch (error) {
            handleApiError(error);
        }
    }, [inputValue, sessionId, selectedOption, languageSelection]);

    const handleScrapData = useCallback(async () => {
        if (!webUrl) {
            return;
        }

        handleModalToggle('webUrlModal', false);

        try {
            toast.success("Scraping data...", {
                autoClose: false,
                closeOnClick: false,
                position: "top-center",
                theme: "colored",
            });

            const response = await _scrapWebsite({ body: { "url": webUrl, "agent": "scrap-data", language_translate: languageSelection } });
            toast.dismiss();

            if (response.data?.sessionId) {
                toast.success("Website data scraped successfully and ready for conversation.", {
                    position: "top-center",
                    theme: "colored",
                });
                setMessages([]);
                setSessionId(response.data.sessionId);
                handleApiResponse(response, 'text');
            }

        } catch (error) {
            showToastMessage();
        }
    }, [webUrl]);

    const handleUpload = useCallback(async () => {
        handleModalToggle('documentModal', false);

        const newMessage = {
            position: "right",
            type: "file",
            title: "you",
            text: selectedFile.name,
            className: "pdf_file",
            data: {
                uri: selectedFile,
                status: {
                    click: false,
                    loading: 0
                },
            }
        };
        appendMessage(newMessage);
        addTypingIndicator();

        try {
            const formData = new FormData();
            formData.append("pdf_file", selectedFile);
            formData.append("agent_name", "scrap-data");
            formData.append("language_translate", JSON.stringify(languageSelection));

            let response;
            response = await documentConversation({ formData });
            setSessionId(response.data.sessionId);
            handleApiResponse(response, 'text');

        } catch (error) {
            if (error.response.data.error == "llm error") {
                removeTypingIndicator();
                showToastMessage("Data size exceeds the allowed limit. Please upload a PDF with less data.");
            } else {
                handleApiError(error);
            }
        }

    }, [selectedFile, selectedOption, sessionId]);

    const setLlmAPI = useCallback(async () => {
        try {
            const response = await _setLlmAPI({ body: { "llm_model": selectedLlm } });
            return response;
        } catch (error) {
            toast.error("An error occurred while setting the LLM API", {
                position: "top-center",
                theme: "colored",
            });
        }
    }, [selectedLlm]);

    // Helper Functions
    const messageFormat = (position, type, title, data) => ({
        position,
        type,
        title,
        [type === "text" ? "text" : "data"]: data
    });

    const appendMessage = (message) => {
        setMessages(prevMessages => [...prevMessages, message]);
    };

    const addTypingIndicator = () => {
        const typingMessage = {
            position: "left",
            type: "text",
            title: "Assistant",
            text: "Processing...",
            className: "processing"
        };
        appendMessage(typingMessage);
    };

    const removeTypingIndicator = () => {
        setMessages(prevMessages => prevMessages.filter(message => !(message.title === "Assistant" && message.text === "Processing...")));
    };

    const handleApiResponse = async (response, type) => {
        try {
            const audioText = response.data.audio_text;
            setAiText(audioText.ai_response);

            removeTypingIndicator();
            if (type === 'audio') {
                const userMessage = messageFormat("right", "text", "You", audioText.user_input);
                appendMessage(userMessage);
            }

            // Convert the AI response from markdown to HTML
            if (audioText.ai_response) {
                const convertedHtml = convertMarkdownToHtml(audioText.ai_response);
                const assistantMessage = messageFormat("left", "text", "Assistant", <div dangerouslySetInnerHTML={{ __html: convertedHtml }} />);
                appendMessage(assistantMessage);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleApiError = (error) => {
        removeTypingIndicator();
        showToastMessage();
        console.error('Error calling API:', error);
    };

    const showToastMessage = (message="An error occurred!") => {
        toast.error(message, {
            position: "top-center",
            theme: "colored",
        });
    };

    // Effects
    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [handleDocumentClick]);

    useEffect(() => {
        if (animationRef.current) {
            animationRef.current.setSpeed(animationSpeed);
        }
    }, [animationSpeed]);

    useEffect(() => {
        function setupSpeechSynthesis() {
            const availableVoices = window.speechSynthesis.getVoices();
            if (availableVoices.length > 0) {
                const uniqueLang = new Set();

                uniqueLang.add('all');

                availableVoices.forEach(voice => {
                    uniqueLang.add(voice.lang);
                });

                langsRef.current = Array.from(uniqueLang);

                voicesRef.current = availableVoices;

                const defaultVoice = availableVoices.find(voice => voice.name === 'Google UK English Female');
                setSelectedLang('all');
                setSelectedVoice(defaultVoice);
            } else {
                window.speechSynthesis.addEventListener('voiceschanged', setupSpeechSynthesis);
            }
        }

        setupSpeechSynthesis();
    }, []);

    useEffect(() => {
        if (selectedLang) {
            if (selectedLang === 'all') {
                setFilteredVoice(voicesRef.current);
            } else {
                const filterLang = voicesRef.current.filter(voice => voice.lang === selectedLang);
                setFilteredVoice(filterLang);
            }
        }
    }, [selectedLang]);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (!listening && transcript !== '') {
            handleSendMessage(transcript);
        } else if (!listening && transcript === '') {
            if (listeningActive) {
                setTimeout(() => {
                    SpeechRecognition.startListening();
                }, 1000);
            }
        }
    }, [listening]);

    useEffect(() => {
        let myTimeout;
        if (aiText) {
            function myTimer() {
                window.speechSynthesis.pause();
                window.speechSynthesis.resume();
                myTimeout = setTimeout(myTimer, 10000);
            }

            window.speechSynthesis.cancel();
            myTimeout = setTimeout(myTimer, 10000);

            const utt = new SpeechSynthesisUtterance(aiText);

            function speakWithVoice() {
                utt.onstart = () => {
                    setAnimationSpeed(1);
                    animationRef.current.play();
                    setIsPlaying(true);
                    setDisablePlay(false);
                };
                utt.onend = () => {
                    clearTimeout(myTimeout);
                    setAnimationSpeed(0);
                    setIsPlaying(false);
                    setDisablePlay(true);

                    if (listeningActive) {
                        setTimeout(() => {
                            SpeechRecognition.startListening();
                        }, 1000);
                    }
                };
                utt.voice = selectedVoice;
                window.speechSynthesis.speak(utt);
            }

            speakWithVoice();
        }
    }, [aiText]);


    useEffect(() => {
        const response = setLlmAPI();
    }, [setLlmAPI]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }

        if (llmsDropdownRef.current && !llmsDropdownRef.current.contains(event.target)) {
            setIsLlmsOpen(false);
        }
    };

    return (
        <>
            <div className="mobile-warnning">
                This version isn&apos;t fully mobile-compatible yet. <br />We&apos;re working on a dedicated app for it.
            </div>
            <div className='home-title'>
                <div className='nav-wrap'>
                    <Link to={'/'}>
                        <div className="logo-wrap">
                            <img src={logo} alt="" />
                            <h1>Audio Bot (W3villa)</h1>
                        </div>
                    </Link>
                    <div className='deskNavigation'>
                        <div onClick={toggleClass} className={isActive ? 'mobileHam closeIcon' : ' mobileHam'}>
                            <div className='HamIcon'>
                                <GiHamburgerMenu />
                            </div>
                            <div className='clsIcon'>
                                <IoMdClose />
                            </div>
                        </div>
                        <div className={isActive ? 'voice-drop active' : ' voice-drop'}>
                            <Link to={'/contact'}><Button className='me-2 add-agent'>Add Agent</Button></Link>
                            <div className="agent-dropdown" ref={llmsDropdownRef}>
                                <Modal show={showModals.llmsModal} onHide={() => handleModalToggle('llmsModal', (false))}>
                                    <Modal.Body >
                                        <div className='text-center'>
                                            <br />
                                            <CgDanger style={{ color: '#dc3545', fontSize: '60' }} />
                                            <br />
                                            <br />
                                            <div>Are you sure you want to change?</div>

                                            <div className='d-flex justify-content-center gap-1 mt-4'>
                                                <Button className='w-150' variant="secondary" onClick={() => handleModalToggle('llmsModal', (false))}>
                                                    Close
                                                </Button>
                                                <Button className='w-150' variant="success" onClick={handleLlmToggel}>
                                                    Save Changes
                                                </Button>
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <div className="agent-dropdown-header" onClick={toggleLlmsDropdown}>
                                    <div className='me-2'>{llms[selectedLlmOption]}</div>
                                    <span className="arrow">{isLlmsOpen ? '▲' : '▼'}</span>
                                </div>
                                {isLlmsOpen && (
                                    <ul className="agent-dropdown-options">
                                        {Object.entries(llms).map(([key, value]) => (
                                            <li key={key} onClick={() => selectLlm(key)} className={key === selectedLlmOption ? 'selected' : ''}>
                                                <div className='li-item'>
                                                    <div className='me-2'>{value}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="agent-dropdown" ref={dropdownRef}>
                                <Modal show={showModals.agentModal} onHide={() => handleModalToggle('agentModal', (false))}>
                                    <Modal.Body >
                                        <div className='text-center'>
                                            <br />
                                            <CgDanger style={{ color: '#dc3545', fontSize: '60' }} />
                                            <br />
                                            <br />
                                            <div>Your previous conversation will be lost !!</div>

                                            <div className='d-flex justify-content-center gap-1 mt-4'>
                                                <Button className='w-150' variant="secondary" onClick={() => handleModalToggle('agentModal', (false))}>
                                                    Close
                                                </Button>
                                                <Button className='w-150' variant="success" onClick={handleAgentToggel}>
                                                    Save Changes
                                                </Button>
                                                <br />
                                                <br />
                                                <br />
                                            </div>

                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <div className="agent-dropdown-header" onClick={toggleDropdown}>
                                    <div className='agent-logo'><img src={agentLogo[selectedOption]} alt="" /></div>
                                    <div className='me-2'>{selectedOption}</div>
                                    <span className="arrow">{isOpen ? '▲' : '▼'}</span>
                                </div>
                                {isOpen && (
                                    <ul className="agent-dropdown-options">
                                        {options.map((option, index) => (
                                            <li key={index} onClick={() => selectOption(option)} className={option === selectedOption ? 'selected' : ''}>
                                                <div className='li-item'>
                                                    <div className='agent-logo'><img src={agentLogo[option]} alt="" /></div>
                                                    <div className='me-2'>{option}</div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <select className='me-2' value={selectedLang ? selectedLang : ''} onChange={(event) => { setSelectedLang(event.target.value) }}>
                                {langsRef.current.map((lang, index) => (
                                    <option key={index} value={lang}>{lang}</option>
                                ))}
                            </select>
                            <select className='w-220' value={selectedVoice ? selectedVoice.name : ''} onChange={handleVoiceChange}>
                                {filteredVoice.map(voice => (
                                    <option key={voice.name} value={voice.name}>{voice.name} ({voice.lang})</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="mobile-warnning">
                This version isn't fully mobile-compatible yet. We're working on a dedicated app for it.
            </div> */}

            <div className='chatWrap'>
                <div className='interpreter-btn-wrap'>
                    {!showRemoteInterpreter && (
                        <>
                            <button className='interpreter-btn' onClick={() => setShowInterpreter(prevState => !prevState)}>
                                {showInterpreter ? <BiChat /> : <BiGlobe />} <span className='dNone-sm'>{showInterpreter ? 'Chat' : 'Interpreter'}</span>
                            </button>
                        </>
                    )}
                    {!showInterpreter && (
                        <>
                            <button className='interpreter-btn' onClick={() => setShowRemoteInterpreter(prevState => !prevState)}>
                                {showRemoteInterpreter ? <BiChat /> : <LiaSignLanguageSolid />} <span className='dNone-sm'>{showRemoteInterpreter ? 'Chat' : 'Remote Interpreter'}</span>
                            </button>
                            {showRemoteInterpreter && (
                                <Instruction />
                            )}
                        </>
                    )}
                </div>
                {showRemoteInterpreter ? <RemoteInterpreter /> : showInterpreter ? (
                    <Interpreter />
                ) : (
                    <div className="home-wrap chat">
                        <ToastContainer />
                        <div className='audio-wrap'>
                            <div className='audio-visulaize'>
                                <Lottie
                                    animationData={speakerAnimation}
                                    loop={true}
                                    autoplay={false}
                                    style={{ width: '180px', height: 'auto', margin: 'auto' }}
                                    lottieRef={animationRef}
                                />
                                {!disablePlay && <div className='play-pause' onClick={handlePlayPause} >
                                    {isPlaying ? <FontAwesomeIcon icon={faCirclePause} /> : <FontAwesomeIcon icon={faCirclePlay} />}
                                </div>}

                            </div>
                            <div className='mic-btn-wrap'>
                                {!listeningActive && !isPlaying && <button className='start-conv' onClick={() => {
                                    setListeningActive(true);
                                    SpeechRecognition.startListening();
                                }}><IoChatbubbleEllipsesOutline /><span className='d-none-sm'>Start Conversation</span></button>}

                                {!listening && listeningActive && !isPlaying && (
                                    <span className='mic-listen mic-mute'>
                                        <FontAwesomeIcon icon={faMicrophoneSlash} style={{ color: "#ff1a1a", }} />
                                    </span>
                                )}

                                {isPlaying && <span className='play-text'>Playing</span>}

                                {listeningActive && listening && <span className='mic-listen'>
                                    <FontAwesomeIcon icon={faMicrophone} beatFade style={{ color: "#63E6BE", }} />
                                    <button onClick={() => {
                                        SpeechRecognition.stopListening();
                                        setListeningActive(false);
                                    }}>Stop listening</button>
                                </span>}

                                <button className='start-conv' onClick={() => handleModalToggle('webUrlModal', true)}><FaLink /><span className='d-none-sm'>Chat With WebUrl</span></button>

                                <Modal className='docUpload' show={showModals.webUrlModal} onHide={() => handleModalToggle('webUrlModal', false)}>
                                    <Modal.Header closeButton className='border-0 pb-0'>

                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='docM'>
                                            <FaLink />
                                        </div>
                                        <h4 className='mb-0'><b> Enter Web URL</b></h4>
                                        <p>Please enter the web url to scrap data</p>
                                        <input className='fileInput' type="text" onChange={handleUrlChange} />
                                        <br />
                                        <br />
                                        <div className='d-flex justify-content-center gap-2'>
                                            <Button className='w-150' variant="secondary" onClick={() => handleModalToggle('webUrlModal', false)}>
                                                Close
                                            </Button>
                                            <Button className='w-150' variant="success" onClick={() => handleScrapData()} >
                                                Scrap
                                            </Button>
                                            <br />
                                            <br />
                                        </div>

                                    </Modal.Body>
                                </Modal>

                                <button className='start-conv' onClick={() => handleModalToggle('documentModal', true)}><IoDocumentTextOutline /><span className='d-none-sm'>Chat With Your Doc</span></button>

                                <Modal className='docUpload' show={showModals.documentModal} onHide={() => handleModalToggle('documentModal', false)}>
                                    <Modal.Header closeButton className='border-0 pb-0'>

                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='docM'>
                                            <IoDocumentTextOutline />
                                        </div>
                                        <h4 className='mb-0'><b> Upload document</b></h4>
                                        <p>Please upload a PDF document</p>
                                        <input className='fileInput' type="file" onChange={handleFileChange} accept=".pdf" />
                                        <br />
                                        <br />
                                        <div className='d-flex justify-content-center gap-2'>
                                            <Button className='w-150' variant="secondary" onClick={() => handleModalToggle('documentModal', false)}>
                                                Close
                                            </Button>
                                            <Button className='w-150' variant="success" onClick={handleUpload} disabled={!selectedFile}>
                                                Upload
                                            </Button>
                                            <br />
                                            <br />
                                        </div>

                                    </Modal.Body>
                                </Modal>

                                <button className='start-conv' onClick={() => handleModalToggle('callModal', true)}><IoMdCall /><span className='d-none-sm'>Get a call</span></button>
                                <FormModal formModal={showModals.callModal} handleForm={handleModalToggle} setCall={setCall} defaultAgent={selectedAgent} />
                            </div>
                        </div>
                        <div className='home' ref={messageListRef} >
                            {messages && <MessageList
                                className='message-list'
                                lockable={true}
                                toBottomHeight={'100%'}
                                dataSource={messages}
                            />}
                        </div>
                        <div className='input_parent' style={{ marginTop: "auto" }}>


                            <div className='custom-input-wrap'>
                                <LanguageInput languageSelection={languageSelection} setLanguageSelection={setLanguageSelection} setSessionId={setSessionId} handleVoiceChange={handleVoiceChange} />
                                <input className='inputChat' type="text" placeholder="Type here..." value={inputValue}
                                    onChange={(event) => setInputValue(event.target.value)} onKeyPress={handleInputKeyPress} />
                                <div>
                                    <button type="button" className="send" onClick={() => handleSendMessage(inputValue)}>
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

Home.propTypes = {
    setCall: PropTypes.func.isRequired,
};

export default Home
